// Core

@import 'core';

// Fonts

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

// Widgets

@import 'widgets';

// Components

@import 'components';

// Layout Components

@import 'layout-components';

// Layout Blueprints

@import 'layout-blueprints';
.Toastify__toast-container {
  width: inherit !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 10px !important;
  background: rgb(211, 47, 47) !important;
}

.Toastify__toast--success {
  border: 1px solid rgb(67, 160, 71) 5 !important;
  border-radius: 10px !important;
  background: rgb(67, 160, 71) !important;
}

.Toastify__toast--warning {
  //   border: 1px solid #ff9800 !important;
  border-radius: 10px !important;
  background: #ff9800 !important;
}

.small-toast {
  min-height: 0px !important;
  min-width: 0px !important;
  font-size: 14px;

  padding: 10px;
}
.Toastify__toast-container--top-center .Toastify__toast-body {
  text-align: center;
}
.custom-bg-success {
  background-color: rgb(240, 245, 234) !important;
}
.custom-bg-warning {
  background-color: rgb(249, 246, 240);
}
body {
  font-size: 0.875rem !important;
}
